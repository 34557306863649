<template>
  <div class="project-aging-report">
    <h2 class="mb-4">Aging Report</h2>
    <!-- ======================= Project Key Index ======================= -->
    <div class="row">
      <project-index-card
        :class="cardCSS"
        :isLoading="isKeyIndexLoading"
        title="Total Submitted Booking"
        :value="projectIndex.totalSubmittedBooking"
      >
      </project-index-card>
      <project-index-card
        :class="cardCSS"
        :isLoading="isKeyIndexLoading"
        title="Total Sales Amount"
        valuePrefix="RM"
        :value="numberFormat(projectIndex.totalSalesAmount, { decimal: 1 })"
        :extraValue="`RM ${numberWithCommas(
          parseInt(projectIndex.totalSalesAmount)
        )}`"
      >
      </project-index-card>
    </div>
    <!-- ========================= Download Report ========================= -->
    <div class="row align-items-end m-2 mt-4">
      <div class="col-12 sm-col-6">
        <h3 class="mt-4">Units</h3>
      </div>
      <div class="col-12 sm-col-6 d-flex justify-content-end">
        <fd-button
          class="main medium"
          :isLoading="isReportDownloading"
          loadingEnabled
          icon="fas fa-download"
          @click="downloadPDF"
          >Download PDF</fd-button
        >
      </div>
    </div>
    <!-- ========================= Units Report ========================== -->
    <!-- <h3 class="mt-4">Units</h3> -->
    <filter-panel
      v-model="isFilterActive"
      bordered
      class="filter mt-2 py-1"
      @clear="clearFilter"
      @submit="filterData"
    >
      <div v-if="isFilterLoading" class="row justify-content-center p-2">
        <spinner></spinner>
      </div>
      <div class="row sm-p-2">
        <fd-input
          v-model="filter['propertyType[partial]']"
          :class="filterColumnCSS"
          label="Property Type"
          name="filterPropertyType"
          type="text"
          @keydown.enter.native.prevent="filterData"
        >
        </fd-input>
        <fd-input
          v-model="filter['projectUnitType[partial]']"
          :class="filterColumnCSS"
          label="Unit Type"
          name="filterProjectUnitType"
          type="text"
          @keydown.enter.native.prevent="filterData"
        >
        </fd-input>
        <fd-input
          v-model="filter['projectPhase[partial]']"
          :class="filterColumnCSS"
          label="Project Phase"
          name="filterProjectPhase"
          type="text"
          @keydown.enter.native.prevent="filterData"
        >
        </fd-input>
        <div class="col-12 md-col-6 row mb-2">
          <fd-input
            v-model="filter['fromSalesPurchaseAgreementPrice']"
            class="col-6 px-1"
            label="SPA Price (Min)"
            name="filterSPAPriceFrom"
            type="number"
            :max="filter['toSalesPurchaseAgreementPrice']"
            @keydown.enter.native.prevent="filterData"
          >
          </fd-input>
          <fd-input
            v-model="filter['toSalesPurchaseAgreementPrice']"
            class="col-6 px-1"
            label="SPA Price (Max)"
            name="filterSPAPriceTo"
            type="number"
            :min="filter['fromSalesPurchaseAgreementPrice']"
            @keydown.enter.native.prevent="filterData"
          >
          </fd-input>
        </div>
        <div class="col-12 md-col-6 row mb-2">
          <fd-date-picker
            v-model="filter['fromBookingCompletedDate']"
            class="col-6 px-1"
            label="Booking Completed Date (Min)"
            name="filterCompletedDateFrom"
            :validator="[]"
            :max="
              $isStringEmpty(filter['toBookingCompletedDate'])
                ? null
                : $moment(filter['toBookingCompletedDate'])
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')
            "
            @keydown.enter.native.prevent="filterData"
            clearable
          >
          </fd-date-picker>
          <fd-date-picker
            v-model="filter['toBookingCompletedDate']"
            class="col-6 px-1"
            label="Booking Completed Date (Max)"
            name="filterCompletedDateTo"
            type="text"
            :min="
              $isStringEmpty(filter['fromBookingCompletedDate'])
                ? null
                : $moment(filter['fromBookingCompletedDate'])
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
            "
            @keydown.enter.native.prevent="filterData"
            clearable
          >
          </fd-date-picker>
        </div>
      </div>
    </filter-panel>
    <vue-good-table
      class="card mt-2"
      mode="remote"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :columns="unitsTableColumns"
      :rows="unitsTableData"
      :totalRows="unitsTablePagination.total"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: perPageOptions,
        dropdownAllowAll: false
      }"
      :sort-options="{
        enabled: false
      }"
      :isLoading="isTableLoading"
    >
      <div slot="loadingContent">
        <div class="d-flex justify-content-center">
          <spinner></spinner>
        </div>
      </div>
      <div slot="emptystate">
        <no-data message="There is no data"></no-data>
      </div>
      <div slot="table-actions" class="p-2">
        <button
          :class="{ active: isFilterActive }"
          class="btn toggle"
          @click="filterToggle"
        >
          <i class="fas fa-filter"></i>
        </button>
      </div>
      <!-- Table Buttons -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'unitPlot'">
          <span class="plot">{{ props.row.plot }}</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { numberFormat, numberWithCommas } from "@/utils/string";
import { projectAgingReport as reportAPI } from "@/api";

export default {
  components: {
    ProjectIndexCard: () => import("@/components/SalesReport/SalesIndexCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      isReportDownloading: false,

      isKeyIndexLoading: false,
      projectIndex: {},

      isTableLoading: false,
      unitsTableData: [],
      unitsTablePagination: {
        total: 0
      },
      unitsTableColumns: [
        {
          label: "Plot",
          field: "unitPlot",
          sortable: false
        },
        {
          label: "Phase",
          field: "phase",
          sortable: false
        },
        {
          label: "Property Type",
          field: "propertyType",
          sortable: false
        },
        {
          label: "SPA Price",
          field: "salesPurchaseAgreementPrice",
          sortable: false,
          formatFn: (price) => `RM ${numberWithCommas(parseInt(price))}`
        },
        {
          label: "On-going",
          field: "numberOfDays",
          sortable: false,
          formatFn: (day) => `${day} days`
        },
        {
          label: "SPA Days Left",
          field: "spaDaysLeft",
          sortable: false,
          formatFn: (day) => `${day} days`
        },
        {
          label: "SPA Deadline",
          field: "spaDueDate",
          sortable: false,
          formatFn: (date) => this.$moment(date).format("DD-MM-YYYY")
        },

        {
          label: "Booking Completed Date",
          field: "bookingCompletedDate",
          sortable: false,
          formatFn: (date) => this.$moment(date).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      isFilterLoading: false,
      filter: {
        "propertyType[partial]": "",
        "projectUnitType[partial]": "",
        "projectPhase[partial]": "",
        fromSalesPurchaseAgreementPrice: "",
        toSalesPurchaseAgreementPrice: "",
        fromBookingCompletedDate: "",
        toBookingCompletedDate: ""
      },

      cardCSS: "col-12 sm-col-6 md-col-3 px-1 mb-2 md-mb-0",
      filterColumnCSS: "col-12 sm-col-6 md-col-4 lg-col-3 px-1 mb-2",

      numberFormat,
      numberWithCommas
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await Promise.all([this.getReport(), this.getUnitsReport()]);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getUnitsReport();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getUnitsReport().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toSalesReport(param) {
      let id = param.row.id;
      this.$router.push({
        name: "ProjectSalesReportDetail",
        params: { id: id }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      this.getReport();
      this.getUnitsReport();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getReport();
      this.getUnitsReport();
    },
    // ============================== API RELATED ==============================
    async getReport() {
      try {
        this.isKeyIndexLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await reportAPI.getReport(this.$route.params.id, {
          queries: {
            ...filteredParam
          }
        });
        this.projectIndex = this._.cloneDeep(data);
        this.isKeyIndexLoading = false;
      } catch (error) {
        this.isKeyIndexLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to get project sales report index. Please try again later."
        });
        throw error;
      }
    },
    async getUnitsReport() {
      try {
        this.isTableLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await reportAPI.getUnitsReport(this.$route.params.id, {
          queries: {
            ...filteredParam
          }
        });

        this.unitsTableData = this._.cloneDeep(data.data);
        this.unitsTablePagination = this._.cloneDeep(data.meta.pagination);

        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get project units sales. Please try again later."
        });
        throw error;
      }
    },
    async downloadPDF() {
      try {
        this.isReportDownloading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        await reportAPI.downloadReport(this.$route.params.id, {
          queries: {
            ...filteredParam
          }
        });
        this.isReportDownloading = false;
      } catch (error) {
        this.isReportDownloading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to download report. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
